* {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  margin: 0;
}

body {
  margin: 0;
  padding: 0 5vw;
  line-height: 1.5;
  letter-spacing: .1em;
  background: black;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: flex;
  flex-direction: column;
}

a {
  color: white;
  text-decoration: none;
}

header { 
  padding: 0 25px 0 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

header p {
  padding-top: 20px;
  letter-spacing: 0.15em;
}

img {
  max-width: 100%;
}

header nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

header nav ul li a {
  padding: 10px 15px;
  font-weight: lighter;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
header nav ul li a:hover {
  color: #fce138;
  border-radius: 15px;
  text-shadow: none;
}
main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.project-img {
  width: 300px;
  height: 300px;
}
.project-img img {
  opacity: 0.0;
  transition: opacity 0.5s ease-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 20px;
  border: 4px solid #fce138;
}

.project-name {
  position: relative;
  text-align: center;
  font-size: larger;
  font-weight: bold;
  top: 50%;
  cursor: pointer;
  z-index: 10;
}

.project-name:hover {
  color: #fce138;
}

.project-name:hover + img{
  clip-path: view-box;
  opacity: 1.0;
}

.contact-container {
  width: 500px;
  height: 100vh;
  background-color: #141414;
  padding: 10px 30px 0 30px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.contact-header {
  display: inline-flex;
}

input {
  background-color: black;
  height: 50px;
}

input, textarea {
  background-color: #2c2d2d;
  width: 95%;
  color: white;
  border: none;
  padding-left:4px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom: 10px;
}

button {
  width: 125px;
  height: 50px;
  background-color: black;
  color: white;
  border: 1px solid white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-right: 20px;
}

.close-button {
  width: 30px;
  height: 30px;
  margin: 25px 0 0 70px;
  border: none;
  background-color: unset;
  font-size: inherit;
}

.error-text {
  color: red;
}

.about-container {
  display: flex;
  flex-wrap: row wrap;
  margin-top: 40px;
  padding: 0 30px 0 30px;
}

.project-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  padding: 0 10px;
}

.selfie {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-radius: 10px;
}

.about-container p {
  margin: 20px 0 0 50px;
}

.about-container button {
  margin: 50px 0 0 50px;
}

li {
  list-style: none;
  margin: 20px 0 0 40px;
}

.stack-list li {
  margin: 0 0 0 40px;
}

footer { 
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 50px;
}

footer p {
  font-variant: small-caps;
  letter-spacing: 0.15em;
  padding-left: 15px;
}

footer nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

footer nav ul li a {
  padding: 10px 15px;
  font-weight: lighter;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

footer nav ul li a:hover {
  color: #fce138;
  border-radius: 15px;
  text-shadow: none;
}

.modal-button {
  padding: 15px 20px;
  background-color: green;
  color: white;
  border: 1px solid white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-right: 20px;
}

.modal-backdrop {
  background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 100;
}

.modal-container {
  background: var(--darker);
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 960px;
}

.modal-title {
  font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}

@media (max-width: 576px){
  .contact-container{
    width: 90%;
  }
  .contact-container a {
    display: block;
    margin-top: 20px;
    position: static;
  }
  .about-container {
    flex-wrap:wrap-reverse;
  }
  .about-container p {
    margin-left: 0;
  }
  .modal-container a {
    display: block;
    width: 250px;
    margin-bottom: 15px;;
  }
  .project-img {
    padding-bottom: 10px;
    margin: auto;
  }
  .project-img img {
    opacity: 1;
  }
  .project-name {
    color: #fce138;
  }
  .project-box {
    width: 100vw;
  }
  header { 
    width: 100%;
    display: flex;
    justify-content: center;
    padding: unset;
  }
  header p {
    font-size: 25px;
    margin: 0 auto;
  }

  header nav {
    display: flex;
    justify-content: center;
    width: 100vw;
  }

  header nav ul {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin:unset;
    padding: unset;
  }

  header nav li {
    padding: unset;
    margin-left:unset
  }

  header nav ul li a {
    padding: unset;
  }
  footer {
    margin-top: 40px;
  }
  footer nav ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding:unset;
    align-items: unset;
  }
  footer nav ul li a {
    font-size: 12px;
    padding: 10px 0;
    
  }
}
